import * as React from "react"

import { Link, navigate } from "gatsby"
import { Stack, Button, Typography } from '@mui/material';

import TranslateManager from "../translations/translateManager"

import Layout from '../components/Layout'


// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Typography style={{margin: "1em 0 .5em 0", fontFamily:"Source Serif Pro", fontSize: "3em"}} align="center" variant="string" component="div">{TranslateManager.getText("TITLE_404")}</Typography>
      <Typography style={{fontSize: "1.2em"}} align="center" variant="string" component="div">{TranslateManager.getText("SUBTITLE_404")}</Typography>

      <Typography variant="string" align="center" style={{cursor: "pointer", fontFamily: "Lexand Deca bold", fontSize: "0.9em", marginTop: "2em"}} component="div" onClick={() => { navigate("/")} }>{TranslateManager.getText("BACK_BTN")}</Typography>
    </Layout>
    
  )
}

export default NotFoundPage
